var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.analysis_table.length > 0)?_c('b-container',{staticClass:"px-0 border",attrs:{"fluid":""}},[(_vm.analysis_table.length > 0)?_c('b-table',{ref:"selectableTable",class:{ 'text-nowrap': !_vm.single_view },attrs:{"items":_vm.analysis_table,"fields":_vm.analysis_fields,"small":"","responsive":"","sticky-header":_vm.fullscreen_mode ? '600px' : '350px',"bordered":"","striped":"","selectable":!_vm.single_view,"select-mode":"multi","stacked":_vm.single_view,"tbody-tr-class":_vm.rowClass},on:{"row-selected":function (items) { return _vm.onRowSelected(items); },"input":function($event){_vm.lock_select = true;
      _vm.syncSelection(true);},"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [(_vm.all_selected)?[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"check-square"},on:{"click":function($event){return _vm.clearSelected()}}})]:[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"square"},on:{"click":function($event){return _vm.selectAllRows()}}})]]},proxy:true},{key:"cell(selected)",fn:function(ref){
      var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('b-icon',{attrs:{"icon":"check-square"}})]:[_c('b-icon',{attrs:{"icon":"square"}})]]}},{key:"cell(hybrid_id)",fn:function(row){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"title":row.item.run_infos.description}},[_vm._v(_vm._s(row.item.run_infos.machine_name.split(" ").slice(1).join(" "))+"-"+_vm._s(row.item.run_infos.ananke_id))])]}},{key:"cell(pcr_state)",fn:function(row){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],attrs:{"title":JSON.stringify(row.item.optic_calibrations) !==
          JSON.stringify({}) &&
          JSON.stringify(row.item.optic_calibrations) !== JSON.stringify([])
          ? 'Used optic calibration'
          : 'Did not use optic calibration'}},[_vm._v(_vm._s(row.item.pcr_state))])]}}],null,false,3739055589)}):_vm._e()],1):_c('b-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-secondary text-center"},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"slash-circle"}}),_vm._v("No analysis data to show. ")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }