<template>
  <b-row class="border-bottom">
    <b-col cols="12">
      <b-overlay :show="runs_datas.length == 0" rounded="sm">
        <b-alert :show="fetching && !error" class="position-fixed fixed-bottom m-0 rounded-0" fade>Fetching data for {{
          runs.length }} selected runs...<b-progress :value="fetchedRunIdsCount" :max="fetchedRunIdsMax" animated
            height="4px"></b-progress></b-alert>
        <b-alert :show="error" class="position-fixed fixed-bottom m-0 rounded-0" variant="danger" fade>Something went
          wrong with the server. Please try again.</b-alert>
        <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success"
          dismissible @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Fetched data from
          {{ runs.length
          }} selected runs.
        </b-alert>
        <b-row style="min-height: 900px" v-if="single_view">
          <b-col md="4" class="border-right" v-show="runs_datas.length > 0">
            <b-row class="border-bottom" style="min-height: 450px">
              <b-col cols="12">
                <bucket-zone :runs_datas.sync="runs_datas" @setup="updateSetup" :analysis_versions="analysis_versions"
                  :analysis_version="analysis_version" :viz_options="viz_options" :infos_fields="infos_fields"
                  :batch_fields="batch_fields" :batches_infos.sync="batches_infos" :single_view="single_view"
                  :fetching="fetching" :error="error" :is-archive="isArchive" v-on:remove-data="removeData"
                  @version="updateAnalysisVersion"></bucket-zone>
              </b-col>
            </b-row>
            <b-row style="min-height: 450px">
              <b-col cols="12">
                <viz-options :data_options="data_options" :main_color_scale="main_color_scale"
                  :viz_options.sync="viz_options" :fetching="fetching" :error="error"></viz-options>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="8" v-show="runs_datas.length > 0">
            <data-plots :runs_datas.sync="runs_datas" :viz_options="viz_options" :main_color_scale="main_color_scale"
              :light_color_scale="light_color_scale" :single_view="single_view"
              :analysis_version="analysis_version"></data-plots>
          </b-col>
        </b-row>
        <b-row style="min-height: 500px" v-else>
          <b-col md="3" class="border-right">
            <bucket-zone :runs_datas.sync="runs_datas" @setup="updateSetup" :analysis_versions="analysis_versions"
              :analysis_version="analysis_version" :viz_options="viz_options" :infos_fields="infos_fields"
              :batch_fields="batch_fields" :batches_infos.sync="batches_infos" :single_view="single_view"
              :fetching="fetching" :error="error" :is-archive="isArchive" v-on:remove-data="removeData"
              @version="updateAnalysisVersion"></bucket-zone>
          </b-col>
          <b-col md="6">
            <data-plots :runs_datas.sync="runs_datas" :viz_options="viz_options" :main_color_scale="main_color_scale"
              :light_color_scale="light_color_scale" :single_view="single_view"
              :analysis_version="analysis_version"></data-plots>
          </b-col>
          <b-col md="3" class="border-left">
            <viz-options :data_options="data_options" :main_color_scale="main_color_scale"
              :viz_options.sync="viz_options" :fetching="fetching" :error="error"></viz-options>
          </b-col>
        </b-row>
        <template #overlay>
          <div class="text-center text-secondary" v-if="!fetching || error">
            <b-icon icon="inbox" font-scale="4"></b-icon>
            <p>
              There is no data to show.<br />Please select at least one run with
              valid data.
            </p>
          </div>
        </template>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import VizOptions from "./data-viz/VizOptions.vue";
import DataPlots from "./data-viz/DataPlots.vue";
import BucketZone from "./data-viz/BucketZone.vue";
import { runAllInBatches } from "../utils/async";
import transformToLegacy from "../utils/fluo_data_adapter"

const CancelToken = axios.CancelToken;

export default {
  name: "DataViz",
  props: [
    "selected_runs",
    "infos_fields",
    "batch_fields",
    "single_view",
    "batches_infos",
    "isArchive"
  ],
  computed: {
    runs: function () {
      var runs = this.selected_runs;
      runs
        .sort(
          (run_info1, run_info2) => run_info1.ananke_id - run_info2.ananke_id
        )
        .sort((run_info1, run_info2) => {
          if (run_info1.machine_name < run_info2.machine_name) {
            return -1;
          } else {
            return 1;
          }
        });
      return runs;
    },
  },
  data: function () {
    return {
      runs_datas: [],
      setup: null,
      analysis_versions: [],
      analysis_version: "",
      custom_version: "",
      run_data_fetch: {},
      cached_runs_datas: [],
      max_cache: 100,
      temps_runs_datas: [],
      fetching: false,
      fetchedRunIdsCount: 0,
      fetchedRunIdsMax: 0,
      data_workflow: ["fluor_mapping", "fluos", "analysis"],
      error: false,
      source: CancelToken.source(),
      dismiss_secs: 5,
      dismiss_countdown: 0,
      viz_options: {},
      display_curve: "raw",
      flat_data: [],
      data_options: {
        leds: [],
        chambers: [],
      },
      main_color_scale: d3
        .scaleOrdinal()
        .domain([1, 2, 3, 4])
        .range(["blue", "black", "green", "red"]),
      light_color_scale: d3
        .scaleOrdinal()
        .domain([1, 2, 3, 4])
        .range(["deepskyblue", "grey", "lightgreen", "pink"]),
    };
  },
  watch: {
    runs: async function () {
      await this.fetchRunsData();
    },
    runs_datas: function () {
      this.getDataOptions();
    }
  },
  created: async function () {
    this.source = CancelToken.source();
    this.run_data = {};
  },
  methods: {
    async fetchRunsData() {
      this.source?.cancel();
      this.source = CancelToken.source();
      this.run_data_fetch = {};
      this.fetching = true;
      this.fetchedRunIdsCount = 0;
      this.fetchedRunIdsMax = this.runs.length * this.data_workflow.length;
      this.runs_datas = [];
      this.temps_runs_datas = [];
      let cached_runs_id = this.runs
        .filter((run) =>
          this.cached_runs_datas.map((run) => run.id).includes(run.id)
        )
        .map((elem) => elem.id);
      let not_cached_runs_id = this.runs
        .filter((run) => !cached_runs_id.includes(run.id))
        .map((elem) => elem.id);
      if (cached_runs_id.length > 0) {
        this.runs_datas = this.runs_datas.concat(
          this.cached_runs_datas.filter((elem) =>
            cached_runs_id.includes(elem.id)
          )
        );
      }
      this.fetchedRunIdsCount = this.runs_datas.length * this.data_workflow.length;
      let fetches = [];
      if (not_cached_runs_id.length > 0) {
        fetches = [];
        for (let run_id of not_cached_runs_id) {
          fetches.push(() => this.fetchRunData(run_id, "fluos"));
        }
        await runAllInBatches({
          batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
          operations: fetches,
        });
        for (let run_id of not_cached_runs_id) {
          let data = {
            id: run_id,
            run_infos: this.runs.filter((elem) => elem.id == run_id)[0],
            selected: true,
            shown: false,
          };
          let run_fluor = this.run_data_fetch[run_id]?.["fluor_mapping"] || [];
          run_fluor.forEach(elem => elem.led = parseInt(elem.led))
          data.fluor_mapping = run_fluor
          this.temps_runs_datas = this.temps_runs_datas.concat(data);
        }
        for (let run_id of not_cached_runs_id) {
          var run_fluos = this.run_data_fetch[run_id]?.fluos || [];
          var chambers = [];
          let run_index = this.temps_runs_datas.findIndex(
            (elem) => elem.id == run_id
          );
          var empty_fluor_mapping =
            this.temps_runs_datas[run_index].fluor_mapping.length == 0;
          for (let run_fluo of run_fluos) {
            if (empty_fluor_mapping) {
              if (this.temps_runs_datas[run_index].fluor_mapping.filter(
                ele => ele.led == run_fluo.led).length == 0) {
                this.temps_runs_datas[run_index].fluor_mapping.push({
                  led: run_fluo.led,
                  channel: run_fluo.led,
                  fluor: "N/A",
                });
              }
            }
            chambers = [
              ...new Set(chambers.concat(Object.keys(run_fluo.fluo))),
            ];
          }
          var common_leds = [
            ...new Set(run_fluos.map((run_fluo) => run_fluo.led)),
          ].filter((led) =>
            this.temps_runs_datas[run_index].fluor_mapping
              .map((ele) => ele.led)
              .includes(led)
          );
          this.temps_runs_datas[run_index].fluor_mapping = this.temps_runs_datas[
            run_index
          ].fluor_mapping.filter((ele) => common_leds.includes(ele.led));
          this.temps_runs_datas[run_index].fluos = {};
          for (let led of common_leds) {
            this.temps_runs_datas[run_index].fluos[led] = run_fluos.filter(
              (elem) => elem.led == led
            );
          }
          this.temps_runs_datas[run_index].chambers = [...new Set(chambers)];
          this.cached_runs_datas = this.cached_runs_datas.concat(
            this.temps_runs_datas[run_index]
          );
          this.runs_datas = this.runs_datas.concat(
            this.temps_runs_datas[run_index]
          );
        }
        fetches = [];
        for (let run_id of not_cached_runs_id) {
          fetches.push(() => this.fetchRunData(run_id, "analysis"));
        }
        await runAllInBatches({
          batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
          operations: fetches,
        });
        fetches = [];
        for (let run_id of not_cached_runs_id) {
          fetches.push(() => this.fetchRunData(run_id, "analysis-versions"));
        }
        await runAllInBatches({
          batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
          operations: fetches,
        });
        for (let run_id of not_cached_runs_id) {
          let run_index = this.runs_datas.findIndex((run) => run.id == run_id);
          if (this.run_data_fetch && this.run_data_fetch[run_id]) {
            var run_analysis = this.run_data_fetch[run_id]["analysis"] ? this.run_data_fetch[run_id]["analysis"] : {}
            if (run_analysis?.data)
              Object.keys(run_analysis.data)
                .filter(
                  (led) =>
                    !this.runs_datas[run_index].fluor_mapping
                      .map((ele) => ele.led)
                      .includes(parseInt(led))
                )
                .forEach((led) => delete run_analysis.data[parseInt(led)])
            this.runs_datas[run_index].analysis = run_analysis
            run_index = this.cached_runs_datas.findIndex(
              (elem) => elem.id == run_id
            )
            this.cached_runs_datas[run_index].analysis = run_analysis
          }
        }
        this.runs_datas.forEach((run, i) => {
          run.shown = i < 50 ? true : false;
        });
      }
      this.analysis_versions = []
      for (let run_data of this.runs_datas) {
        if (run_data.analysis !== undefined) {
          const versions = Object.keys(run_data.analysis)
          for (let version of versions) {
            if (!this.analysis_versions.includes(version)) {
              this.analysis_versions.push(version)
            }
          }
        }
      }
      this.analysis_version = this.analysis_versions[0]
      this.custom_version = this.analysis_version + ' (Custom)'
      this.fetching = false;

      if (this.cached_runs_datas.length > this.max_cache)
        this.cached_runs_datas.slice(-this.max_cache);

      this.temps_runs_datas = [];
      this.run_data_fetch = {};
      this.fetchedRunIdsCount = this.fetchedRunIdsMax;
      this.dismiss_countdown = this.dismiss_secs;
    },

    async fetchRunData(run_id, data_type) {
      const uri = "/api/v1/run/" + data_type;
      try {
        const axios_request = axios
          .get(uri, {
            params: { run_id: run_id },
            cancelToken: this.source.token,
          })
        const response = await axios_request
        switch (data_type) {
          case "fluos": {
            const { data: fluorData, mappingRules } = transformToLegacy(response.data)
            this.run_data_fetch[run_id] ??= {};
            this.run_data_fetch[run_id]["fluor_mapping"] = mappingRules;
            this.fetchedRunIdsCount++;
            this.run_data_fetch[run_id] ??= {};
            this.run_data_fetch[run_id].fluos = fluorData
            this.fetchedRunIdsCount++;
            break;
          }
          case "analysis": {
            break;
          }
          case "analysis-versions": {
            this.run_data_fetch[run_id] ??= {};
            this.run_data_fetch[run_id].analysis = {}
            for (let version of response.data) {
              this.run_data_fetch[run_id].analysis[version.version] = (await axios
                .get("/api/v1/run/analysis", {
                  params: { run_id: run_id, version: version.version, signature: version.signature },
                  cancelToken: this.source.token,
                })).data
              if (this.run_data_fetch[run_id].analysis[version.version] !== undefined) {
                this.run_data_fetch[run_id].analysis[version.version]["date"] = version.date
                if (Object.keys(this.run_data_fetch[run_id].analysis[version.version]?.data).includes("interpretation")) {
                  this.run_data_fetch[run_id].analysis[version.version].interpretation = this.run_data_fetch[
                    run_id].analysis[version.version].data.interpretation
                  delete this.run_data_fetch[run_id].analysis[version.version].data.interpretation
                }
              }
            }
            this.fetchedRunIdsCount++;
          }
        }
      }
      catch (error) {
        if (axios.isCancel(error)) {
          console.warn("Request cancelled due to a refresh fetching");
          throw error;
        }
      }
    },
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
    getDataOptions() {
      var distinct_leds = [];

      if (this.runs_datas.length > 0)
        distinct_leds = [
          ...new Set(
            this.runs_datas.flatMap((run_data) =>
              run_data.fluor_mapping
                ? run_data.fluor_mapping.map((ele) => ele.led)
                : []
            )
          ),
        ];

      var led_options = [];
      for (let led of distinct_leds) {
        let distinct_fluors = [
          ...new Set(
            this.runs_datas.flatMap((run_data) =>
              run_data.fluor_mapping
                .filter((ele) => ele.led == led)
                .map((ele) => ele.fluor)
            )
          ),
        ];
        let fluor = distinct_fluors[0];
        if (distinct_fluors.length > 1) {
          fluor = distinct_fluors.find((ele) => ele !== "N/A");
        }
        led_options.push({
          led: led,
          channel: this.runs_datas
            .flatMap(
              (run_data) =>
                run_data.fluor_mapping.find(
                  (ele) => ele.led == led && ele.fluor == fluor
                )?.channel
            )
            .find((ele) => ele !== undefined),
          fluor: fluor,
        });
      }
      var chamber_options = [
        ...new Set(this.runs_datas.flatMap((run_data) => run_data.chambers)),
      ];
      this.data_options.leds = led_options;
      this.data_options.chambers = chamber_options;
    },
    removeData(new_data) {
      if (!this.single_view) {
        this.runs_datas = new_data;
        var run_ids = this.runs_datas.map((ele) => ele.run_infos.id);
        var selected_runs = this.runs.filter((ele) => run_ids.includes(ele.id));
        this.$emit("update:selected_runs", selected_runs);
      }
    },
    updateAnalysisVersion(version) {
      this.analysis_version = version
    },
    async fetchAnalysis(run_id) {
      const run_index = this.temps_runs_datas.findIndex(ele => ele.id == run_id)
      const analysis_results = (await axios.post("/api/v1/run/analysis", this.setup, {
        params: {
          run_id: run_id
        }
      })).data
      this.fetchedRunIdsCount++;
      const now = new Date();
      this.temps_runs_datas[run_index].analysis[this.custom_version] = analysis_results
      this.temps_runs_datas[run_index].analysis[this.custom_version]["date"] = now.toISOString()
      if (Object.keys(analysis_results?.data).includes("interpretation")) {
        this.temps_runs_datas[run_index].analysis[this.custom_version].interpretation = this.temps_runs_datas[
          run_index].analysis[this.custom_version].data.interpretation
        delete this.temps_runs_datas[run_index].analysis[this.custom_version].data.interpretation
      }
    },
    async fetchAnalyses() {
      this.fetching = true;
      this.temps_runs_datas = [];
      this.temps_runs_datas = this.runs_datas;
      this.runs_datas = [];
      this.fetchedRunIdsCount = 0;
      this.fetchedRunIdsMax = this.runs.length;
      let fetches = [];
      for (let run_id of this.runs.map(ele => ele.id)) {
        fetches.push(() => this.fetchAnalysis(run_id));
      }
      await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      });
      this.runs_datas = this.temps_runs_datas;
      this.temps_runs_datas = [];
      this.run_data_fetch = {};
      if (this.runs_datas.find(ele => Object.keys(ele.analysis[this.custom_version].data).length > 0)) {
        this.analysis_versions = [this.custom_version].concat(this.analysis_versions)
        this.updateAnalysisVersion(this.custom_version)
      }
      else {
        this.updateAnalysisVersion(this.analysis_versions[0])
      }
      this.fetchedRunIdsCount = this.fetchedRunIdsMax;
      this.fetching = false;
      this.dismiss_countdown = this.dismiss_secs;
    },
    updateSetup(setup) {
      this.setup = setup
      this.analysis_versions = this.analysis_versions.filter(ele => !ele.endsWith(" (Custom)"))
      if (this.setup !== null) {
        this.fetchAnalyses()
      }
      else {
        this.updateAnalysisVersion(this.analysis_versions[0])
      }
    }
  },
  components: {
    VizOptions,
    DataPlots,
    BucketZone,
  },
};
</script>