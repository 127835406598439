<template>
  <div v-bind:class="{ fullscreen: fullscreen_mode }" @mouseover="overed = true" @mouseleave="overed = false"
    class="no-outline-focus" v-on:keyup.delete="removeAllData()" tabindex="0">
    <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success" dismissible
      @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Permalink copied to clipboard -
      <strong>{{ permalink }}</strong>
    </b-alert>
    <b-alert :show="failed_json_countdown" class="position-fixed fixed-bottom m-0 rounded-0" dismissible
      variant="warning" @dismissed="failed_json_countdown = 0" @dismiss-count-down="JSONCountDownChanged">
      The protocol JSON is invalid, no custom analysis values will be shown.
    </b-alert>
    <div class="fullscreen_option d-none d-md-block" v-if="overed && !single_view" @click="
      fullscreen_mode = !fullscreen_mode;
    overed = false;
    ">
      <b-icon icon="x-circle-fill" variant="secondary" font-scale="1.5" v-if="fullscreen_mode"></b-icon>
      <b-icon icon="fullscreen" variant="secondary" font-scale="1.5" v-else></b-icon>
    </div>
    <h4>
      <span v-if="single_view" class="mr-2">Run data</span>
      <span v-else class="mr-1">Bucket zone
        <small class="text-secondary" v-if="runs_datas.length > 0">
          ({{ runs_datas.length }})</small>
      </span>
      <small>
        <b-iconstack class="clickable mr-1" @click="revertSelection()" v-if="runs_datas.length > 0 && !single_view"
          title="Revert runs selection">
          <b-icon stacked icon="square-fill" variant="secondary"></b-icon>
          <b-icon stacked icon="arrow-down-up" scale="0.75" variant="white"></b-icon>
        </b-iconstack>
        <b-iconstack class="clickable mr-1" @click="toggleAllVisility()" v-if="runs_datas.filter((ele) => ele.selected).length > 0 &&
          !single_view &&
          isMultiplePlotView
        " title="Toggle plot visibility of selected runs">
          <b-icon stacked icon="square-fill" variant="secondary"></b-icon>
          <b-icon stacked icon="eye" scale="0.75" variant="white"></b-icon>
        </b-iconstack>
        <edit-multiple-tags v-if="canEditMultipleTags" :run_ids="selectedRunsIds" small></edit-multiple-tags>
        <b-iconstack v-if="runs_datas.filter((ele) => ele.selected).length > 0 && !single_view
        " class="clickable mr-2" @click="removeAllData()" title="Remove selected runs">
          <b-icon stacked icon="square-fill" variant="danger"></b-icon>
          <b-icon stacked icon="trash" scale="0.75" variant="white"></b-icon>
        </b-iconstack>
      </small>
      <small>
        <help-view>You can visualize and export here all the run data.</help-view></small>
    </h4>
    <b-tabs v-show="runs_datas.length > 0" v-model="selected_tab">
      <b-tab title="Main">
        <div class="mt-2">
          <div class="controls">
            <b-button size="sm" class="download-btn" @click="downloadRunsInfos()" title="Download runs infos"><b-icon
                icon="download" class="mr-1"></b-icon>Download</b-button>
            <b-button size="sm" v-b-toggle.analysis_collapse><b-icon icon="arrow-repeat"
                class="mr-1"></b-icon>Reanalyze</b-button>
            <b-form-checkbox v-model="isMultiplePlotView" class="multiple-plot-view-switch"
              :class="isMultiplePlotView && 'active-switch'" @change="hideAllGraphs" switch v-if="!single_view">Multiple
              graphs view</b-form-checkbox>
          </div>
        </div>
        <b-collapse id="analysis_collapse">
          <div class="mt-2">
            <hr>
            <b-form-group label="Protocol" label-for="protocol_file" label-cols-xl="3">
              <b-form-file v-model="protocol_file" :state="Boolean(protocol_file)" id="protocol_file"
                placeholder="Choose a protocol JSON file..." drop-placeholder="Drop file here..."
                accept="application/json"></b-form-file>
            </b-form-group>
            <div class="controls">
              <b-button block variant="primary" :disabled="protocol_file === null || (fetching && !error)"
                @click="launchAnalysis" title="Download runs infos"><b-icon icon="arrow-repeat"
                  class="mr-1"></b-icon>Analyze</b-button>
            </div>
            <hr>
          </div>
        </b-collapse>
        <div class="mt-2">
          <b-table ref="dataList" :items="runs_datas.map((ele) => ele.run_infos)" :fields="bucket_fields" small
            responsive striped bordered :sticky-header="fullscreen_mode ? '600px' : '360px'" :selectable="!single_view"
            select-mode="multi" v-bind:class="{ 'text-nowrap': !single_view }" :stacked="single_view"
            @row-selected="(items) => onRowSelected(items)" @input="
              lock_select = true;
            syncSelection(true);
            " @row-clicked="lock_select = false" :tbody-tr-class="rowClass" :tbody-tr-attr="rowAttr">
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected"><b-icon icon="check-square"></b-icon>
              </template>
              <template v-else>
                <b-icon icon="square"></b-icon>
              </template>
            </template>
            <template #head(selected)>
              <template v-if="all_selected"><b-icon icon="check-square" @click="clearSelected()"
                  style="cursor: pointer"></b-icon>
              </template>
              <template v-else>
                <b-icon icon="square" @click="selectAllRows()" style="cursor: pointer"></b-icon>
              </template>
            </template>
            <template #cell(hybrid_id)="row">
              <span>{{ row.item.machine_name.split(" ").slice(1).join(" ") }}-{{
                row.item.ananke_id
                }}</span>
            </template>
            <template #cell(action)="row">
              <b-button @click="getPermalink(row.item.id)" size="sm" class="p-0" title="Get permalink"
                variant="dark"><b-icon icon="link"></b-icon></b-button>
              <b-button @click="toggleVisibility(row.item.id)" size="sm" class="p-0 ml-1" title="Toggle plot visibility"
                variant="dark" v-if="runs_datas.find((ele) => ele.run_infos.id == row.item.id)
                  .shown
                "><b-icon icon="eye"></b-icon></b-button>
              <b-button @click="toggleVisibility(row.item.id)" size="sm" class="p-0 ml-1" title="Toggle plot visibility"
                variant="light" v-else><b-icon icon="eye-slash"></b-icon></b-button>
              <b-button @click="removeData(row.item.id)" size="sm" class="p-0 ml-1" variant="danger"
                title="Remove data"><b-icon icon="trash"></b-icon></b-button>
            </template>
            <template #cell(view)="row">
              <run-infos :run_datas="runs_datas.find((ele) => ele.run_infos.id == row.item.id)
                " :infos_fields="infos_fields" :batch_fields="batch_fields" :batches_infos.sync="batches_infos"
                :is-archive="isArchive"></run-infos>
              <run-tags :run_infos="runs_datas.find((ele) => ele.run_infos.id == row.item.id)
                .run_infos
                "></run-tags>
              <run-pictures :run_datas="runs_picture_datas.find(
                (ele) => ele.run_infos.id == row.item.id
              )
                "></run-pictures>
              <run-logs :run_infos="runs_datas.find((ele) => ele.run_infos.id == row.item.id)
                .run_infos
                "></run-logs>
            </template>
          </b-table>
        </div>
      </b-tab>
      <b-tab title="Analysis" v-if="!fetching || error">
        <div class="mt-2" v-if="analysis_versions !== undefined && analysis_versions.length > 0">
          <b-form-group label="Version" label-for="analysis-version" label-cols-xl="3" class="mt-1 mb-0">
            <b-form-select id="analysis-version" v-model="selected_version"
              :options="analysis_versions"></b-form-select>
          </b-form-group>
        </div>
        <div class="mt-2">
          <download-analysis :runs_datas="runs_datas" :analysis_version="selected_version" :viz_options="viz_options"
            :fields="analysis_fields"></download-analysis>
        </div>
        <div class="mt-2">
          <analysis-table v-if="runs_datas.length > 0" :runs_datas="runs_datas" :analysis_version="selected_version"
            :viz_options="viz_options" :fields="analysis_fields" :single_view="single_view"
            :fullscreen_mode="fullscreen_mode"></analysis-table>
        </div>
      </b-tab>
      <b-tab title="Interpretation" v-if="(!fetching || error) && interpretations.length > 0">
        <div class="mt-2">
          <b-form-group label="Version" label-for="analysis-version" label-cols-xl="3" class="mt-1 mb-0">
            <b-form-select id="analysis-version" v-model="selected_version"
              :options="analysis_versions"></b-form-select>
          </b-form-group>
        </div>
        <div class="mt-2">
          <interpretation-table :interpretations="interpretations"
            :analysis_version="selected_version"></interpretation-table>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import * as d3 from "d3";
import HelpView from "../HelpView.vue";
import { formatHealthMessage } from "../../utils/message";
import AnalysisTable from "./AnalysisTable.vue";
import InterpretationTable from "./InterpretationTable.vue";
import RunInfos from "./data-details/RunInfos.vue";
import RunPictures from "./data-details/RunPictures.vue";
import DownloadAnalysis from "./data-details/DownloadAnalysis.vue";
import RunLogs from "./data-details/RunLogs.vue";
import RunTags from "./data-details/RunTags.vue";
import EditMultipleTags from "./data-details/EditMultipleTags.vue";
const cycleFormat = d3.format(".2f");
const globalFormat = d3.format("~g");

export default {
  name: "BucketZone",
  props: [
    "runs_datas",
    "analysis_versions",
    "analysis_version",
    "viz_options",
    "infos_fields",
    "batch_fields",
    "single_view",
    "batches_infos",
    "fetching",
    "error",
    "isArchive"
  ],
  computed: {
    runs_picture_datas: function () {
      return this.runs_datas.map((run_data) => {
        var filtered_data = {};
        for (let led in run_data.fluos) {
          let led_data = run_data.fluos[led].filter((ele) => ele.picture);
          if (led_data.length > 0) {
            filtered_data[led] = led_data;
          }
        }
        return {
          run_infos: run_data.run_infos,
          data: filtered_data,
        };
      });
    },
    bucket_fields: function () {
      var bucket_fields = [
        {
          key: "selected",
          label: "Selected",
          tdClass: "align-middle text-center",
          thStyle: "text-align: center",
        },
        {
          key: "hybrid_id",
          label: "#",
          tdClass: "align-right text-right",
        },
        {
          key: "action",
          label: "Actions",
          tdClass: "align-middle text-center",
        },
        {
          key: "view",
          label: "View",
          tdClass: "align-middle text-center",
        },
        {
          key: "description",
          label: "Description",
        },
      ];
      if (this.single_view) {
        bucket_fields = ["machine_name", { "key": "ananke_id", "label": "Chronos ID" }, "description", "view"];
      }
      return bucket_fields;
    },

    selectedRunsIds() {
      return this.runs_datas
        .filter((ele) => ele.selected)
        .map((ele) => ele.run_infos.id);
    },

    canEditMultipleTags() {
      return this.selectedRunsIds.length > 0 && !this.single_view;
    },

    interpretations() {
      if (this.runs_datas !== undefined) {
        let interpretations = this.runs_datas.filter(run_data => run_data.analysis[this.selected_version]?.interpretation).map(run_data => {
          return {
            run_infos: run_data.run_infos,
            results: run_data.analysis[this.selected_version].interpretation.results,
            date: run_data.analysis[this.selected_version].date,
            selected: run_data.selected
          }
        })
        let parsedResults = []
        interpretations.forEach((interpretation) => {
          let parsedResult = {
            run_infos: interpretation.run_infos,
            date: interpretation.date,
            selected: interpretation.selected
          }
          if (interpretation.results.find(ele => ele.name == 'Compute values')) {
            const compute_values = interpretation.results.find(
              (ele) => ele.name == 'Compute values'
            ).values
            const compute_labels = interpretation.results.find(
              (ele) => ele.name == 'Compute labels'
            ).values
            const compute_units = interpretation.results.find(
              (ele) => ele.name == 'Compute units'
            ).values
            const compute_formats = interpretation.results.find(
              (ele) => ele.name == 'Compute formats'
            ).values
            let compute_labels_units = []
            for (const compute_index in compute_values) {
              const compute_label_unit = {
                label: compute_labels[compute_index],
                unit: compute_units[compute_index],
              }
              compute_labels_units.push(compute_label_unit)
            }
            compute_labels_units = compute_labels_units.filter(
              (item, index, self) =>
                index ===
                self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
            )
            parsedResult.compute_results = compute_labels_units.map((ele) => {
              let value = null
              let format = null
              for (let index = compute_values.length - 1; index >= 0; index--) {
                if (
                  compute_labels[index] == ele.label &&
                  compute_units[index] == ele.unit &&
                  compute_values[index]
                ) {
                  value = compute_values[index]
                  format = compute_formats[index]
                  break
                }
              }
              return {
                label: ele.label,
                unit: ele.unit,
                value: value,
                format: format,
              }
            })
            parsedResult.valid_internal_control = interpretation.results.find(
              (ele) => ele.name == 'Valid internal control'
            ).values[0] == 1
          } else {
            parsedResult.results = interpretation.results
          }
          parsedResults.push(parsedResult)
        })
        return parsedResults
      }
      return []
    }
  },
  data: function () {
    return {
      fullscreen_mode: false,
      overed: false,
      analysis_fields: [
        { key: "chamber", label: "Chamber" },
        {
          key: "led",
          label: "LED",
        },
        {
          key: "fluor",
          label: "Fluorophore",
        },
        { key: "pcr_state", label: "Amplification" },
        {
          key: "cycle_threshold",
          label: "Cycle threshold",
          formatter: (value) => {
            return value == "-inf" ? "NA" : cycleFormat(value);
          },
        },
        {
          key: "eneg",
          label: "Eneg",
          formatter: (value) => globalFormat(value),
        },
        {
          key: "epos",
          label: "Epos",
          formatter: (value) => globalFormat(value),
        },
        {
          key: "signal_level",
          label: "Signal level",
          formatter: (value) => globalFormat(value),
        },
        {
          key: "delta_rfu",
          label: "Delta RFU",
          formatter: (value) => globalFormat(value),
        },
        {
          key: "max_ddy",
          label: "Max DDY",
          formatter: (value) => {
            return value == "-inf" ? "NA" : cycleFormat(value);
          },
        },
        {
          key: "xmax_ddy",
          label: "Max DDY X2",
          formatter: (value) => {
            return value == "-inf" ? "NA" : cycleFormat(value);
          },
        },
        {
          key: "ct_diff",
          label: "Ct difference",
          formatter: (value) => globalFormat(value),
        },
        { key: "date", label: "Analysis date (UTC)" },
      ],
      all_selected: false,
      sync_select_timeout: null,
      sync_select_interval: null,
      lock_select: false,
      select_syncing: false,
      permalink: null,
      dismiss_countdown: 0,
      total_secs: 5,
      selected_tab: 0,
      isMultiplePlotView: true,
      selected_version: "",
      protocol_file: null,
      failed_json_countdown: 0
    };
  },
  watch: {
    fullscreen_mode: function () {
      document.body.style.overflow = this.fullscreen_mode ? "hidden" : "";
    },

    runs_datas(runs_datas) {
      for (const run_data of runs_datas) {
        run_data.shown = this.isMultiplePlotView;
      }
    },

    analysis_version() {
      this.selected_version = this.analysis_version
    },

    selected_version() {
      if (this.selected_version !== this.analysis_version) {
        this.$emit("version", this.selected_version)
      }
    }
  },
  methods: {
    selectAllRows() {
      this.lock_select = false;
      this.$refs.dataList.selectAllRows();
    },
    clearSelected() {
      this.lock_select = false;
      this.$refs.dataList.clearSelected();
    },
    revertSelection() {
      this.runs_datas.forEach(
        (run_data) => (run_data.selected = !run_data.selected)
      );
    },
    async launchAnalysis() {
      try {
        const setup = JSON.parse(await this.protocol_file.text())
        this.$emit("setup", setup)
        this.protocol_file = null
      }
      catch (error) {
        this.failed_json_countdown = 5;
        this.$emit("setup", null)
        this.protocol_file = null
      }
    },
    toggleAllVisility() {
      this.runs_datas
        .filter((ele) => ele.selected)
        .forEach((run_data) => (run_data.shown = !run_data.shown));
    },
    removeAllData() {
      this.$emit(
        "remove-data",
        this.runs_datas.filter((ele) => !ele.selected)
      );
    },
    removeData(run_id) {
      this.$emit(
        "remove-data",
        this.runs_datas.filter((ele) => ele.run_infos.id !== run_id)
      );
    },
    toggleVisibility(run_id) {
      if (this.isMultiplePlotView) {
        const run_data = this.runs_datas.find(
          (ele) => ele.run_infos.id === run_id
        );
        run_data.shown = !run_data.shown;
      } else {
        for (const run_data of this.runs_datas) {
          run_data.shown =
            run_data.run_infos.id === run_id ? !run_data.shown : false;
        }
      }
    },
    getPermalink(run_id) {
      var vm = this;
      var run_infos = this.runs_datas.find(
        (ele) => ele.run_infos.id == run_id
      ).run_infos;
      var permalink =
        window.location.origin +
        "/run/" +
        run_infos.machine_name +
        "/" +
        run_infos.ananke_id;
      permalink = encodeURI(permalink);
      vm.permalink = permalink;
      navigator.clipboard.writeText(vm.permalink).then(
        () => (vm.dismiss_countdown = vm.total_secs),
        () => (vm.dismiss_countdown = vm.total_secs)
      );
    },
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
    JSONCountDownChanged(dismiss_countdown) {
      this.failed_json_countdown = dismiss_countdown;
    },
    onRowSelected(items) {
      if (!this.lock_select) {
        this.runs_datas.forEach((run_data) => {
          run_data.selected = false;
          if (
            items.filter((ele) => ele.id == run_data.run_infos.id).length > 0
          ) {
            run_data.selected = true;
          }
        });
      }
      this.all_selected =
        items.length == this.runs_datas.length && items.length > 0;
    },
    syncSelection(lock_select) {
      var vm = this;
      clearTimeout(vm.sync_select_timeout);
      if (!vm.select_syncing) {
        vm.select_syncing = true;
        var table = vm.$refs.dataList;
        if (table === undefined) {
          vm.sync_select_timeout = setTimeout(vm.syncSelection, 100);
        } else {
          if (vm.runs_datas.length > 0 && vm.runs_datas[0].run_infos) {
            table.items.forEach((item, index) => {
              var run_data = vm.runs_datas.find(
                (ele) => ele.run_infos.id == item.id
              );
              if (run_data.selected) {
                table.selectRow(index);
              } else {
                table.unselectRow(index);
              }
            });
            vm.lock_select = lock_select;
          }
        }
        vm.select_syncing = false;
      }
    },
    downloadRunsInfos() {
      var column_names = Object.keys(this.runs_datas[0].run_infos).filter(
        (ele) => ele !== "id"
      );
      var rows = [];
      this.runs_datas.forEach((data_entry) => {
        var new_row = [];
        var run_infos = data_entry.run_infos;
        column_names.forEach((column) => {
          new_row.push(run_infos[column]);
        });
        rows.push(new_row);
      });
      var array_data = [column_names].concat(rows);
      var csv_content =
        'data:text/csv;charset=utf-8,"' +
        array_data.map((ele) => ele.join('","')).join('"\n"') +
        '"';
      var encoded_uri = encodeURI(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        "gaia_runs_infos_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (
        item.health_status === "CRITICAL" ||
        item.health_status === "BLOCK_MACHINE"
      ) {
        return "row-error font-weight-bold";
      }
      if (item.run_status === "INVALID") {
        return "row-error font-weight-bold";
      }
      if (item.wrong_chip) {
        return "row-error font-weight-bold";
      }
      if (
        item.chip_id?.length > 0 &&
        (!item.batch_id || item.batch_id.length == 0)
      ) {
        return "row-error font-weight-bold";
      }
      if (item.health_status === "WARNING") {
        return "row-warning font-weight-bold";
      }
    },
    rowAttr(item, type) {
      if (!item || type !== "row") return;
      if (
        (item.health_status === "CRITICAL" ||
          item.health_status === "BLOCK_MACHINE") &&
        item.last_message !== null
      ) {
        return {
          title: "Last message: " + formatHealthMessage(item.last_message),
        };
      }
      if (item.run_status === "INVALID") {
        return {
          title: "Invalid run status",
        };
      }
      if (item.wrong_chip) {
        return {
          title: "Wrong chip",
        };
      }
      if (
        item.chip_id?.length > 0 &&
        (!item.batch_id || item.batch_id.length == 0)
      ) {
        return {
          title: "Chip ID without batch",
        };
      }
      if (item.health_status === "WARNING" && item.last_message !== null) {
        return {
          title: "Last message: " + formatHealthMessage(item.last_message),
        };
      }
    },
    hideAllGraphs() {
      if (!this.isMultiplePlotView) {
        for (const run_data of this.runs_datas) {
          run_data.shown = false;
        }
      }
    },
  },
  created: function () {
    clearInterval(this.sync_select_interval);
    this.sync_select_interval = setInterval(this.syncSelection, 100);
  },
  components: {
    HelpView,
    AnalysisTable,
    InterpretationTable,
    RunInfos,
    RunPictures,
    DownloadAnalysis,
    RunLogs,
    RunTags,
    EditMultipleTags,
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.fullscreen_option {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  cursor: pointer;
  z-index: 999;
}

.fullscreen {
  background-color: white;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 40px;
}

.fullscreen .fullscreen_options {
  position: fixed;
}

.no-outline-focus {
  outline: none;
}

.controls {
  display: flex;
  align-items: center;
  column-gap: 1em;
}

.download-btn {
  flex: none;
}

.multiple-plot-view-switch,
.multiple-plot-view-switch>>>* {
  cursor: pointer;
}

.multiple-plot-view-switch:not(.active-switch)>>>label {
  color: rgba(0, 0, 0, 40%);
}
</style>
