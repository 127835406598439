<template>
  <div>
    <b-container fluid class="px-0 border" v-if="analysis_table.length > 0">
      <b-table ref="selectableTable" :items="analysis_table" :fields="analysis_fields" small responsive
        :sticky-header="fullscreen_mode ? '600px' : '350px'" bordered striped :selectable="!single_view"
        select-mode="multi" v-bind:class="{ 'text-nowrap': !single_view }" :stacked="single_view"
        @row-selected="(items) => onRowSelected(items)" @input="
          lock_select = true;
        syncSelection(true);
        " @row-clicked="rowClicked" :tbody-tr-class="rowClass" v-if="analysis_table.length > 0">
        <template #head(selected)>
          <template v-if="all_selected"><b-icon icon="check-square" @click="clearSelected()"
              style="cursor: pointer"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="square" @click="selectAllRows()" style="cursor: pointer"></b-icon>
          </template>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <b-icon icon="check-square"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="square"></b-icon>
          </template>
        </template>
        <template #cell(hybrid_id)="row">
          <span v-b-tooltip.hover.right :title="row.item.run_infos.description">{{
            row.item.run_infos.machine_name.split(" ").slice(1).join(" ")
          }}-{{ row.item.run_infos.ananke_id }}</span>
        </template>
        <template #cell(pcr_state)="row">
          <span v-b-tooltip.hover.left :title="JSON.stringify(row.item.optic_calibrations) !==
            JSON.stringify({}) &&
            JSON.stringify(row.item.optic_calibrations) !== JSON.stringify([])
            ? 'Used optic calibration'
            : 'Did not use optic calibration'
            ">{{ row.item.pcr_state }}</span>
        </template>
      </b-table>
    </b-container>
    <b-container fluid v-else>
      <p class="text-secondary text-center">
        <b-icon icon="slash-circle" class="mx-1"></b-icon>No analysis data to
        show.
      </p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AnalysisTable",
  props: [
    "runs_datas",
    "analysis_version",
    "viz_options",
    "fields",
    "single_view",
    "fullscreen_mode",
  ],
  watch: {
    runs_datas(runs_datas) {
      for (const run_data of runs_datas) {
        run_data.shown = this.isMultiplePlotView;
      }
    },
  },
  data: function () {
    return {
      selected: false,
      all_selected: false,
      sync_select_timeout: null,
      sync_select_interval: null,
      lock_select: false,
      select_syncing: false,
      overed: false,
      dismiss_countdown: 0,
      total_secs: 5,
      selected_tab: 0,
      isMultiplePlotView: true,
    };
  },
  computed: {
    analysis_table: function () {
      var analysis_table = [];
      var analysis_datas = this.runs_datas.filter(
        (run_data) => run_data.analysis[this.analysis_version] !== undefined
      );
      analysis_datas = analysis_datas.filter((run_data) => {
        if (run_data.analysis[this.analysis_version].data !== undefined) {
          var run_fluors = Object.keys(run_data.analysis[this.analysis_version].data);
          var run_chambers = [];
          run_fluors.forEach((fluor) => {
            run_chambers = run_chambers.concat(
              Object.keys(run_data.analysis[this.analysis_version].data[fluor])
            );
          });
          run_chambers = [...new Set(run_chambers)];
          return (
            run_fluors.filter((fluor) =>
              this.viz_options.display_leds.includes(
                run_data.fluor_mapping.find((ele) =>
                  ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
                ).led
              )
            ).length > 0 &&
            run_chambers.filter((chamber) =>
              this.viz_options.display_chambers.includes(chamber)
            )
          );
        }
      });
      analysis_datas.forEach((run_data, run_index) => {
        var included_fluors = Object.keys(run_data.analysis[this.analysis_version].data).filter(
          (fluor) =>
            this.viz_options.display_leds.includes(
              run_data.fluor_mapping.find((ele) =>
                ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
              ).led
            )
        );
        included_fluors.forEach((fluor, fluor_index) => {
          var led = run_data.fluor_mapping.find((ele) =>
            ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
          ).led;
          var fluor_data = run_data.analysis[this.analysis_version].data[fluor];
          var included_chambers = Object.keys(fluor_data).filter((chamber) =>
            this.viz_options.display_chambers.includes(chamber)
          );
          included_chambers.forEach((chamber, chamber_index) => {
            var chamber_data = fluor_data[chamber];
            var ampli_variant =
              chamber_data.PCR_STATE == "POSITIVE" ? "valid" : "error";
            var run_sep =
              run_index < analysis_datas.length - 1 &&
              fluor_index == included_fluors.length - 1 &&
              chamber_index == included_chambers.length - 1;
            analysis_table.push({
              optic_calibrations: run_data.analysis[this.analysis_version].optic_calibrations,
              run_infos: run_data.run_infos,
              selected: run_data.selected,
              chamber: chamber,
              led: led,
              fluor: fluor,
              cycle_threshold: chamber_data.Ct,
              eneg: chamber_data.ENeg,
              epos: chamber_data.EPos,
              pcr_state: chamber_data.PCR_STATE,
              signal_level: chamber_data.signal_level,
              delta_rfu: chamber_data.delta_rfu,
              max_ddy: chamber_data.max_ddy,
              xmax_ddy: chamber_data.xmax_ddy,
              ct_diff: chamber_data.ct_diff,
              date: run_data.analysis[this.analysis_version].date,
              _cellVariants: { pcr_state: ampli_variant },
              run_sep: run_sep,
            });
          });
        });
      });
      return analysis_table;
    },
    analysis_fields: function () {
      var analysis_fields = this.fields;
      if (!this.single_view) {
        analysis_fields = [
          {
            key: "selected",
            label: "Selected",
            tdClass: "align-middle text-center",
            thStyle: "text-align: center",
          },
          {
            key: "hybrid_id",
            label: "#",
            tdClass: "align-right text-right",
            stickyColumn: true,
          },
        ].concat(analysis_fields);
      }
      return analysis_fields;
    },
    selectedRunsIds() {
      return this.analysis_table
        .filter((ele) => ele.selected)
        .map((ele) => ele.run_infos.id);
    },
    canEditMultipleTags() {
      return this.selectedRunsIds.length > 0 && !this.single_view;
    },
  },
  methods: {
    selectAllRows() {
      this.lock_select = false;
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.lock_select = false;
      this.$refs.selectableTable.clearSelected();
    },
    revertSelection() {
      this.runs_datas.forEach(
        (run_data) => (run_data.selected = !run_data.selected)
      );
    },
    toggleVisibility(run_id) {
      if (this.isMultiplePlotView) {
        const run_data = this.runs_datas.find(
          (ele) => ele.run_infos.id === run_id
        );
        run_data.shown = !run_data.shown;
      } else {
        for (const run_data of this.runs_datas) {
          run_data.shown =
            run_data.run_infos.id === run_id ? !run_data.shown : false;
        }
      }
    },
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
    onRowSelected(items) {
      if (!this.lock_select) {
        this.analysis_table.forEach((run_data) => {
          run_data.selected = false;
          if (
            items.filter((ele) => ele.run_infos.id == run_data.run_infos.id)
              .length > 0
          ) {
            run_data.selected = true;
          }
        });
      }

      this.all_selected =
        items.length == this.runs_datas.length * 3 && items.length > 0;
      this.checkRunsDatasSelected();
    },
    checkRunsDatasSelected() {
      this.analysis_table.forEach((el) => {
        if (el.selected) {
          this.runs_datas.forEach((run) => {
            if (el.run_infos.id === run.run_infos.id) {
              run.selected = true;
            }
          });
        } else {
          this.runs_datas.forEach((run) => {
            if (el.run_infos.id === run.run_infos.id) {
              run.selected = false;
            }
          });
        }
      });
    },
    rowClicked(item) {
      this.lock_select = false;
      if (item.selected) {
        this.analysis_table.forEach((analyse) => {
          if (analyse.run_infos.id === item.run_infos.id) {
            this.runs_datas.forEach((run) => {
              if (analyse.run_infos.id === run.run_infos.id) {
                run.selected = false;
              }
            });
          }
        });
      }
    },
    syncSelection(lock_select) {
      var vm = this;
      clearTimeout(vm.sync_select_timeout);
      if (!vm.select_syncing) {
        vm.select_syncing = true;
        var table = vm.$refs.selectableTable;
        if (table === undefined) {
          vm.sync_select_timeout = setTimeout(vm.syncSelection, 100);
        } else {
          if (vm.runs_datas.length > 0 && vm.runs_datas[0].run_infos) {
            table.items.forEach((item, index) => {
              var run_data = vm.runs_datas.find(
                (ele) => ele.run_infos.id == item.run_infos.id
              );
              if (run_data.selected) {
                table.selectRow(index);
              } else {
                table.unselectRow(index);
              }
            });
            vm.lock_select = lock_select;
          }
        }
        vm.select_syncing = false;
      }
    },
    rowClass(item, type) {
      if (this.single_view) return;
      if (!item || type !== "row") return;
      if (item.run_sep && item.selected) return "tr-border-bottom table-active";
      if (item.run_sep) return "tr-border-bottom";
      if (item.selected) return "table-active";
    },
    hideAllGraphs() {
      if (!this.isMultiplePlotView) {
        for (const run_data of this.runs_datas) {
          run_data.shown = false;
        }
      }
    },
  },
  created: function () {
    clearInterval(this.sync_select_interval);
    this.sync_select_interval = setInterval(this.syncSelection, 100);
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.table-responsive {
  margin: 0 !important;
}

table {
  margin: 0 !important;
}

.no-data {
  margin-top: 40%;
}

div>>>.tr-border-bottom {
  border-bottom: solid 3px rgb(43, 43, 43) !important;
}

div>>>td.table-valid {
  background-color: rgba(40, 167, 69, 0.75) !important;
}

div>>>td.table-valid {
  color: white !important;
}

div>>>td.table-error {
  background-color: rgba(220, 53, 69, 0.75) !important;
}

div>>>td.table-error {
  color: white !important;
}

div>>>tr.table-active {
  background-color: rgba(114, 34, 129, 0.75) !important;
}
</style>