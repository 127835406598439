<template>
  <div v-bind:class="{ fullscreen: fullscreen_mode, single_view: single_view }" @mouseover="overed = true"
    @mouseleave="overed = false">
    <div class="fullscreen_option d-none d-md-block" v-if="overed && !single_view" @click="
      fullscreen_mode = !fullscreen_mode;
    overed = false;
    ">
      <b-icon icon="x-circle-fill" variant="secondary" font-scale="1.5" v-if="fullscreen_mode"></b-icon>
      <b-icon icon="fullscreen" variant="secondary" font-scale="1.5" v-else></b-icon>
    </div>
    <h4 class="component-title">
      Data plots
      <small>
        <b-icon icon="image" variant="secondary" class="clickable mr-2" title="Show plot as a png image"
          v-if="flat_data.length > 0" @click="plotAsPng"></b-icon>
        <help-view>You can visualize here the data plots depending on your
          options.</help-view></small>
    </h4>
    <div id="lines_chart" v-show="flat_data.length > 0"></div>
    <div id="popover_anchor"></div>
    <div class="plot_tooltip border bg-white rounded px-1 m-2" v-show="show_tooltip && overed_data != null">
      <span v-if="overed_data != null">
        <span v-if="overed_data.run_infos !== undefined">
          <strong>Machine name: </strong>{{ overed_data.run_infos.machine_name
          }}<br />
          <strong>ID: </strong>{{ overed_data.run_infos.ananke_id }}<br />
          <span v-if="overed_data.run_infos.description !== ''"><strong>Description: </strong>{{
            overed_data.run_infos.description
          }}<br /></span>
          <span v-if="overed_data.run_infos.samples !== ''"><strong>Samples: </strong>{{ overed_data.run_infos.samples
          }}<br /></span>
        </span>
        <strong>Chamber: </strong>{{ overed_data.chamber }}<br />
        <span v-if="viz_options.display_curve == 'raw'">
          <strong>Cycle: </strong>{{ closest_value.infos.cycle }}<br />
          <strong>Side: </strong>{{ closest_value.infos.side }}
        </span>
      </span>
    </div>
    <b-modal v-model="show_png" hide-footer hide-header size="xl" centered>
      <div><b-img :src="plot_png" center fluid alt="Plot export"></b-img></div>
    </b-modal>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as saveSvgAsPng from "save-svg-as-png";
import HelpView from "../HelpView.vue";

export default {
  name: "DataPlots",
  props: [
    "runs_datas",
    "viz_options",
    "main_color_scale",
    "light_color_scale",
    "single_view",
    "analysis_version"
  ],
  computed: {
    selected_runs: function () {
      return this.runs_datas
        .filter((ele) => ele.selected)
        .map((ele) => ele.run_infos.id);
    },
    flat_data: function () {
      var flat_data = [];
      this.runs_datas
        .filter((ele) => ele.shown)
        .forEach((run_data) => {
          if (this.viz_options.display_curve == "raw") {
            flat_data = flat_data.concat(this.parseFluoData(run_data));
          } else {
            if (run_data.analysis[this.analysis_version] !== undefined) {
              flat_data = flat_data.concat(this.parseAnalysisData(run_data));
            }
          }
        });
      return flat_data;
    },
  },
  data: function () {
    return {
      fullscreen_mode: false,
      overed: false,
      mouse_coords: null,
      overed_data: null,
      closest_value: null,
      show_tooltip: false,
      svg: d3.select("#lines_chart"),
      svgGroup: null,
      height_value: 600,
      width_value: 1500,
      margin: { top: 20, bottom: 20, left: 60, right: 20 },
      stroke_width: 1.5,
      draw_graph_timeout: null,
      plot_width: 0,
      plot_height: 0,
      x_axis: null,
      y_axis: null,
      plot_png: null,
      show_png: false,
    };
  },
  watch: {
    viz_options: {
      deep: true,
      handler: function () {
        this.svg = d3.select("#lines_chart");
        this.drawGraph();
      },
    },
    selected_runs: function () {
      this.svg = d3.select("#lines_chart");
      this.drawGraph();
    },
    flat_data: function () {
      this.svg = d3.select("#lines_chart");
      this.drawGraph();
    },
    fullscreen_mode: function () {
      document.body.style.overflow = this.fullscreen_mode ? "hidden" : "";
      this.drawGraph();
    },
  },
  methods: {
    parseFluoData(run_data) {
      var flat_data = [];
      let mintimestamps = [];
      let mincycles0 = {};
      Object.keys(run_data.fluos).forEach((led) => {
        led = parseInt(led);
        let mincycle0 = d3.min(
          run_data.fluos[led].filter((d) => d.cycle == 0),
          (d) => d.timestamp
        );
        if (mincycle0 == undefined) {
          mincycle0 = 0;
        }
        mincycles0[led] = mincycle0;
        mintimestamps.push(
          d3.min(
            run_data.fluos[led].filter(
              (ele) => ele.cycle > 0 && ele.timestamp > mincycle0
            ),
            (ele) => ele.timestamp
          )
        );
      });
      let mintimestamp = Math.min(...mintimestamps);
      Object.keys(run_data.fluos)
        .filter((ele) => this.viz_options.display_leds.includes(parseInt(ele)))
        .forEach((led) => {
          led = parseInt(led);
          run_data.fluos[led].forEach((led_data) => {
            Object.keys(led_data.fluo)
              .filter((ele) => this.viz_options.display_chambers.includes(ele))
              .forEach((chamber) => {
                var infos = {
                  run_id: run_data.run_infos.id,
                  channel: run_data.fluor_mapping.find((ele) => ele.led == led)
                    .channel,
                  chamber: chamber,
                  cycle: led_data.cycle,
                  side: led_data.side,
                };
                if (this.viz_options.start_from_one) {
                  if (
                    led_data.cycle > 0 &&
                    led_data.timestamp > mincycles0[led]
                  ) {
                    flat_data.push({
                      infos: infos,
                      type: "raw",
                      x: led_data.timestamp - mintimestamp,
                      y: led_data.fluo[chamber],
                    });
                  }
                } else {
                  flat_data.push({
                    infos: infos,
                    type: "raw",
                    x: led_data.timestamp,
                    y: led_data.fluo[chamber],
                  });
                }
              });
          });
        });
      return flat_data;
    },
    parseAnalysisData(run_data) {
      var flat_data = [];
      var included_fluors = Object.keys(run_data.analysis[this.analysis_version]?.data).filter(
        (fluor) =>
          this.viz_options.display_leds.includes(
            run_data.fluor_mapping.find((ele) =>
              ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
            ).led
          )
      );
      included_fluors.forEach((fluor) => {
        var led = run_data.fluor_mapping.find((ele) =>
          ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
        ).led;
        var fluor_data = run_data.analysis[this.analysis_version].data[fluor];
        var included_chambers = Object.keys(fluor_data).filter((chamber) =>
          this.viz_options.display_chambers.includes(chamber)
        );
        included_chambers.forEach((chamber) => {
          var chamber_data = fluor_data[chamber];
          var infos = {
            run_id: run_data.run_infos.id,
            channel: run_data.fluor_mapping.find((ele) => ele.led == led)
              .channel,
            chamber: chamber,
          };
          for (let cycle_index in chamber_data.cycles) {
            if (typeof chamber_data.processed_curve[cycle_index] == "number") {
              flat_data.push({
                infos: infos,
                type: "processed",
                x: chamber_data.cycles[cycle_index],
                y: chamber_data.processed_curve[cycle_index],
              });
            }
          }
          if (chamber_data.PCR_STATE == "POSITIVE") {
            if (typeof chamber_data.CTThreshold2 == "number") {
              flat_data.push({
                infos: infos,
                type: "threshold",
                x: chamber_data.x2[0],
                y: chamber_data.CTThreshold2,
              });
              flat_data.push({
                infos: infos,
                type: "threshold",
                x: chamber_data.x2[chamber_data.x2.length - 1],
                y: chamber_data.CTThreshold2,
              });
            }
            for (let x2_index in chamber_data.x2) {
              if (
                chamber_data.sigmoid_fitting[x2_index] !== undefined &&
                typeof chamber_data.sigmoid_fitting[x2_index] == "number"
              ) {
                flat_data.push({
                  infos: infos,
                  type: "sigmoid",
                  x: chamber_data.x2[x2_index],
                  y: chamber_data.sigmoid_fitting[x2_index],
                });
              }
              if (
                chamber_data.ddy[x2_index] !== undefined &&
                typeof chamber_data.ddy[x2_index] == "number"
              ) {
                flat_data.push({
                  infos: infos,
                  type: "ddy",
                  x: chamber_data.x2[x2_index],
                  y: chamber_data.ddy[x2_index],
                });
              }
            }
          }
        });
      });
      return flat_data;
    },
    dotsData(flat_data) {
      var new_flat_data = [];
      flat_data.forEach((data) => {
        var run_infos = this.runs_datas.find(
          (ele) => ele.run_infos.id == data.infos.run_id
        ).run_infos;
        new_flat_data.push({
          run_id: data.infos.run_id,
          run_infos: run_infos,
          channel: data.infos.channel,
          chamber: data.infos.chamber,
          type: data.type,
          x: data.x,
          y: data.y,
        });
      });
      return new_flat_data;
    },
    nestData(flat_data) {
      var group_data = d3.groups(
        flat_data,
        (d) => d.infos.run_id,
        (d) => d.infos.channel,
        (d) => d.infos.chamber
      );
      var nestData = group_data.flatMap((data) => {
        var run_id = data[0];
        var run_infos = this.runs_datas.find(
          (ele) => ele.run_infos.id == run_id
        ).run_infos;
        return data[1].flatMap((data) => {
          var channel = data[0];
          return data[1].flatMap((data) => {
            var chamber = data[0];
            var values = data[1];
            return {
              run_id: run_id,
              run_infos: run_infos,
              channel: channel,
              chamber: chamber,
              values: values,
            };
          });
        });
      });
      return nestData;
    },
    drawSVGGroup() {
      this.svg.select("svg").remove();
      this.svgGroup = this.svg
        .append("svg")
        .attr("viewBox", `0 0 ${this.width_value} ${this.height_value}`)
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left + this.stroke_width * 2},${this.stroke_width * 2
          })`
        );
    },
    drawCoordLines(mouseCoords) {
      var HLine = [
        [0, mouseCoords[1]],
        [this.plot_width, mouseCoords[1]],
      ];
      var VLine = [
        [mouseCoords[0], 0],
        [mouseCoords[0], this.plot_height],
      ];
      this.svgGroup
        .selectAll("g.lines-coord")
        .selectAll(".coords")
        .data([HLine, VLine])
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", "lightgrey")
        .attr("stroke-width", this.stroke_width)
        .attr("d", (d) => {
          return d3
            .line()
            .x((d) => d[0])
            .y((d) => d[1])(d);
        });
    },
    drawYCoord(mouseCoords) {
      var ygroup = this.svgGroup.append("g").attr("class", "text-coord");
      var yxpos = mouseCoords[0] < this.plot_width / 2 ? this.plot_width : 5;
      var yanchor = mouseCoords[0] < this.plot_width / 2 ? "end" : "start";
      var yypos =
        mouseCoords[1] > this.plot_height / 2
          ? mouseCoords[1] - this.stroke_width * 2
          : mouseCoords[1] + this.stroke_width;
      var ybaseline =
        mouseCoords[1] > this.plot_height / 2 ? "baseline" : "hanging";
      var ytext = ygroup
        .append("text")
        .attr("transform", "translate(" + yxpos + " ," + yypos + ")")
        .attr("font-size", "smaller")
        .attr("class", "ytext")
        .style("text-anchor", yanchor)
        .attr("alignment-baseline", ybaseline)
        .attr("fill", "grey")
        .text(d3.format("~s")(this.y_axis.invert(mouseCoords[1])));
      var yrect = ytext.node().getBBox();
      var yxdelay = mouseCoords[0] < this.plot_width / 2 ? yrect.width : 0;
      var yydelay =
        mouseCoords[1] > this.plot_height / 2
          ? yrect.height + this.stroke_width
          : -this.stroke_width;
      ygroup
        .insert("rect", ".ytext")
        .attr("width", yrect.width)
        .attr("height", yrect.height)
        .attr("x", yxpos - yxdelay)
        .attr("y", mouseCoords[1] - yydelay)
        .attr("fill", "white");
    },
    drawXCoord(mouseCoords) {
      var xgroup = this.svgGroup.append("g").attr("class", "text-coord");
      var xypos =
        mouseCoords[1] > this.plot_height / 2
          ? this.stroke_width
          : this.plot_height - this.stroke_width;
      var xanchor = mouseCoords[1] > this.plot_height / 2 ? "end" : "start";
      var xxpos =
        mouseCoords[0] > this.plot_width / 2
          ? mouseCoords[0] - this.stroke_width * 2
          : mouseCoords[0] + this.stroke_width;
      var xbaseline =
        mouseCoords[0] > this.plot_width / 2 ? "baseline" : "hanging";
      var xtext = xgroup
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -xypos)
        .attr("y", xxpos)
        .attr("font-size", "smaller")
        .attr("class", "xtext")
        .style("text-anchor", xanchor)
        .attr("alignment-baseline", xbaseline)
        .attr("fill", "grey")
        .text(d3.format("~s")(this.x_axis.invert(mouseCoords[0])));
      var xrect = xtext.node().getBBox();
      var xydelay =
        mouseCoords[1] > this.plot_height / 2
          ? 0
          : xrect.width - this.stroke_width;
      var xxdelay =
        mouseCoords[0] > this.plot_width / 2
          ? xrect.height + this.stroke_width
          : -this.stroke_width;
      xgroup
        .insert("rect", ".xtext")
        .attr("width", xrect.height)
        .attr("height", xrect.width)
        .attr("x", mouseCoords[0] - xxdelay)
        .attr("y", xypos - xydelay)
        .attr("fill", "white");
    },
    drawCoords(mouseCoords) {
      this.svgGroup.selectAll("g.lines-coord").selectAll("path").remove();
      this.svgGroup.selectAll("g.text-coord").remove();
      this.drawCoordLines(mouseCoords);
      if (
        !this.viz_options.no_scale_y ||
        this.viz_options.display_curve != "raw"
      ) {
        this.drawYCoord(mouseCoords);
      }
      this.drawXCoord(mouseCoords);
    },
    removeCoords() {
      this.svgGroup.selectAll("g.lines-coord").selectAll("path").remove();
      this.svgGroup.selectAll("g.text-coord").remove();
    },
    getClosestValue() {
      var x_value = this.x_axis.invert(this.mouse_coords[0]);
      var values_dist = this.overed_data.values.map((ele) =>
        Math.abs(x_value - ele.x)
      );
      var dist_min = d3.min(values_dist);
      var closest_value = null;
      for (let index in values_dist) {
        if (values_dist[index] == dist_min) {
          closest_value = this.overed_data.values[index];
        }
      }
      this.closest_value = closest_value;
    },
    displayTooltip(event) {
      this.show_tooltip = true;
      var mouse_coords = d3.pointer(event);
      var popover_coords = d3.pointer(
        event,
        d3.select("#popover_anchor").node()
      );
      var tooltip = d3.select(".plot_tooltip");
      if (mouse_coords[0] < this.plot_width / 2) {
        tooltip.style("left", popover_coords[0] + "px");
        tooltip.style("right", null);
      } else {
        tooltip.style("left", null);
        tooltip.style(
          "right",
          document.body.clientWidth - popover_coords[0] + "px"
        );
      }
      if (mouse_coords[1] > this.plot_height / 2) {
        tooltip.style("top", null);
        tooltip.style("bottom", window.innerHeight - popover_coords[1] + "px");
      } else {
        tooltip.style("bottom", null);
        tooltip.style("top", popover_coords[1] + "px");
      }
    },
    drawGraphRect() {
      this.svgGroup.append("g").attr("class", "lines-coord");
      this.svgGroup
        .append("rect")
        .attr("fill", "transparent")
        .attr("width", this.plot_width)
        .attr("height", this.plot_height)
        .on("mousemove", (event) => {
          var mouseCoords = d3.pointer(event);
          this.drawCoords(mouseCoords);
          this.mouse_coords = null;
          this.overed_data = null;
        })
        .on("mouseout", () => {
          this.removeCoords();
          this.mouse_coords = null;
          this.overed_data = null;
        });
    },
    drawXAxis() {
      var xLabel = "Time (seconds)";
      if (this.viz_options.display_curve != "raw") {
        xLabel = "Cycle";
      }
      this.x_axis = d3
        .scaleLinear()
        .domain(
          d3.extent(this.flat_data, function (d) {
            return d.x;
          })
        )
        .range([0, this.plot_width]);
      this.svgGroup
        .append("g")
        .attr("transform", "translate(0," + this.plot_height + ")")
        .call(d3.axisBottom(this.x_axis).tickFormat(d3.format("~s")));
      this.svgGroup
        .append("text")
        .attr(
          "transform",
          "translate(" +
          this.plot_width / 2 +
          " ," +
          (this.plot_height + this.margin.top + 15) +
          ")"
        )
        .style("text-anchor", "middle")
        .text(xLabel);
    },
    drawYAxis() {
      this.y_axis = d3
        .scaleLinear()
        .domain([
          d3.min(this.flat_data, function (d) {
            return d.y;
          }),
          d3.max(this.flat_data, function (d) {
            return d.y;
          }),
        ])
        .range([this.plot_height, 0]);
      if (
        !this.viz_options.no_scale_y ||
        this.viz_options.display_curve != "raw"
      ) {
        this.svgGroup
          .append("g")
          .call(d3.axisLeft(this.y_axis).tickFormat(d3.format("~s")));
      } else {
        this.svgGroup.append("g").call(d3.axisLeft(this.y_axis).tickValues([]));
      }
      this.svgGroup
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - this.margin.left)
        .attr("x", 0 - this.plot_height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text("Relative Fluorescence Unit (RFU)");
    },
    setOpacity(data) {
      var opacity = this.runs_datas.find(
        (ele) => ele.run_infos.id == data.run_id
      ).selected
        ? 0.8
        : 0.2;
      return opacity;
    },
    selectRun(data) {
      if (!this.single_view) {
        let selected_run = this.runs_datas.find(
          (ele) => ele.run_infos.id == data.run_id
        );
        selected_run.selected = !selected_run.selected;
      }
    },
    drawLines(data, colorScale) {
      this.svgGroup
        .selectAll(".line")
        .data(data)
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", (d) => colorScale(d.channel))
        .style("opacity", this.setOpacity)
        .style("cursor", "pointer")
        .attr("stroke-width", (data) => {
          var overed_data =
            JSON.stringify(this.overed_data) === JSON.stringify(data);
          var stroke_width = overed_data
            ? this.stroke_width * 4
            : this.stroke_width;
          return stroke_width;
        })
        .attr("d", (d) => {
          var y_axis = d3
            .scaleLinear()
            .domain([
              d3.min(d.values, function (d) {
                return d.y;
              }),
              d3.max(d.values, function (d) {
                return d.y;
              }),
            ])
            .range([this.plot_height, 0]);
          return d3
            .line()
            .x((d) => this.x_axis(d.x))
            .y((d) =>
              this.viz_options.no_scale_y &&
                this.viz_options.display_curve == "raw"
                ? y_axis(d.y)
                : this.y_axis(d.y)
            )(d.values);
        })
        .on("mousemove", (event, data) => {
          var mouseCoords = d3.pointer(event);
          this.drawCoords(mouseCoords);
          d3.select(event.currentTarget).attr(
            "stroke-width",
            this.stroke_width * 4
          );
          this.mouse_coords = mouseCoords;
          this.overed_data = data;
          this.displayTooltip(event);
          this.getClosestValue();
        })
        .on("mouseout", () => {
          this.removeCoords();
          d3.select(event.currentTarget).attr(
            "stroke-width",
            this.stroke_width
          );
          this.mouse_coords = null;
          this.overed_data = null;
          this.show_tooltip = false;
        })
        .on("click", (event, data) => {
          var mouseCoords = d3.pointer(event);
          d3.select(event.currentTarget).attr(
            "stroke-width",
            this.stroke_width * 4
          );
          this.mouse_coords = mouseCoords;
          this.overed_data = data;
          this.selectRun(data);
        });
    },
    drawDots(data, colorScale) {
      this.svgGroup
        .selectAll(".circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("fill", (d) => colorScale(d.channel))
        .attr("stroke", "none")
        .style("opacity", this.setOpacity)
        .style("cursor", "pointer")
        .attr("cx", (d) => this.x_axis(d.x))
        .attr("cy", (d) => this.y_axis(d.y))
        .attr("r", (data) => {
          var overed_data =
            JSON.stringify(this.overed_data) === JSON.stringify(data);
          var r = overed_data ? this.stroke_width * 4 : this.stroke_width * 2;
          return r;
        })
        .on("mousemove", (event, data) => {
          var mouseCoords = d3.pointer(event);
          this.drawCoords(mouseCoords);
          d3.select(event.currentTarget).attr("r", this.stroke_width * 4);
          this.mouse_coords = mouseCoords;
          this.overed_data = data;
          this.displayTooltip(event);
        })
        .on("mouseout", () => {
          this.removeCoords();
          d3.select(event.currentTarget).attr("r", this.stroke_width * 2);
          this.mouse_coords = null;
          this.overed_data = null;
          this.show_tooltip = false;
        })
        .on("click", (event, data) => {
          var mouseCoords = d3.pointer(event);
          d3.select(event.currentTarget).attr("r", this.stroke_width * 4);
          this.mouse_coords = mouseCoords;
          this.overed_data = data;
          this.selectRun(data);
        });
    },
    drawFigures() {
      var raw_curve = this.flat_data.filter((data) => data.type == "raw");
      var processed_curves = this.flat_data.filter(
        (data) => data.type == "processed"
      );
      var sigmoid_curves = this.flat_data.filter(
        (data) => data.type == "sigmoid"
      );
      var ddy_curves = this.flat_data.filter((data) => data.type == "ddy");
      var theshold_line = this.flat_data.filter(
        (data) => data.type == "threshold"
      );
      switch (this.viz_options.display_curve) {
        case "sigmoid":
          this.drawLines(
            this.nestData(sigmoid_curves),
            this.light_color_scale,
            0.5
          );
          this.drawLines(
            this.nestData(ddy_curves),
            this.light_color_scale,
            0.5
          );
          this.drawLines(
            this.nestData(processed_curves),
            this.main_color_scale
          );
          this.drawDots(this.dotsData(processed_curves), this.main_color_scale);
          break;
        case "threshold":
          this.drawLines(this.nestData(sigmoid_curves), this.light_color_scale);
          this.drawLines(this.nestData(ddy_curves), this.light_color_scale);
          this.drawLines(this.nestData(theshold_line), this.main_color_scale);
          break;
        case "processed":
          this.drawLines(
            this.nestData(processed_curves),
            this.main_color_scale
          );
          break;
        default:
          this.drawLines(this.nestData(raw_curve), this.main_color_scale);
          break;
      }
    },
    drawGraph() {
      clearTimeout(this.draw_graph_timeout);
      this.height_value = document.getElementById("lines_chart").clientHeight;
      this.width_value = document.getElementById("lines_chart").clientWidth;
      if (
        this.width_value == 0 ||
        this.height_value == 0 ||
        (this.fullscreen_mode &&
          this.width_value != document.body.clientWidth) ||
        (!this.fullscreen_mode && this.width_value == document.body.clientWidth)
      ) {
        this.draw_graph_timeout = setTimeout(this.drawGraph, 100);
      } else {
        this.plot_width =
          this.width_value -
          this.margin.left -
          this.margin.right -
          this.stroke_width * 2;
        this.plot_height =
          this.height_value -
          this.margin.top -
          this.margin.bottom -
          this.stroke_width * 2;
        this.drawSVGGroup();
        this.drawXAxis();
        this.drawYAxis();
        this.drawGraphRect();
        this.drawFigures();
        if (this.mouse_coords != null) {
          this.drawCoords(this.mouse_coords);
        }
      }
    },
    onResize() {
      this.drawGraph();
    },
    plotAsPng() {
      var svg = this.svg.select("svg").node();
      if (svg !== null && svg !== undefined) {
        var vm = this;
        saveSvgAsPng
          .svgAsPngUri(svg, {
            backgroundColor: "white",
            scale: 2,
          })
          .then((uri) => {
            vm.plot_png = uri;
            vm.show_png = true;
          });
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    HelpView,
  },
};
</script>

<style scoped>
#lines_chart {
  width: 100%;
  height: 460px;
}

.fullscreen_option {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  cursor: pointer;
  z-index: 999;
}

.fullscreen {
  background-color: white;
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 10px;
}

.fullscreen .fullscreen_options {
  position: fixed;
}

#lines_chart svg {
  width: 100%;
  height: 100%;
}

.fullscreen #lines_chart {
  height: 100%;
}

.single_view #lines_chart {
  height: 850px;
}

.fullscreen .component-title {
  display: none;
}

.plot_tooltip {
  position: fixed;
  font-size: 0.8rem;
  max-width: 350px;
  z-index: 999;
}

#popover_anchor {
  position: fixed;
  top: 0;
  left: 0;
}

.clickable {
  cursor: pointer;
}
</style>